.welcomeChatBubble {
  &__message {
    font-size: 16px;
    line-height: 25px;
    background-color: #ffffff;
    border-radius: 12px;
    border-top-left-radius: 0;
    padding: 12px 20px;
    box-shadow: 4px 4px 0px 0px #0000000d;

    h1,
    h2,
    p {
      display: inline;
    }

    h1,
    h2 {
      font-size: 16px;
      line-height: 25px;
    }
    h1 {
      font-weight: normal;
    }
  }
}
