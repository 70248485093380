.app-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main-content {
  height: 100%;
  overflow: hidden;
  flex-grow: 1;
}

.footer {
  margin-top: 12px;
  margin-bottom: 20px;
  position: relative;
  padding: 0 12px;

  &__disclaimer {
    font-size: 14px;
    text-align: center;
    line-height: 22px;
  }

  &__credit {
    margin-top: 8px;
    font-size: 12px;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;

    &__logo {
      fill: #002938;
      width: 100px;
    }
  }
}
