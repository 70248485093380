.textArea {
  width: 100%;
  border: 0;
  outline: 0;
  resize: none;
  display: block;
  font-size: 16px;
  line-height: 21px;

  &::placeholder {
    color: var(--color-slate);
  }

  &:disabled {
    background-color: #FAFAFA;
  }
}
