.popularQuestions {
  animation: fadeIn 500ms 700ms forwards;
  opacity: 0;
  padding: 4px 8px 0px 8px;
  margin-bottom: 12px;

  &__title {
    font-size: 14px;
  }
  
  &__list {
    list-style-type: none;
    padding: 0;
    display: flex;
    gap: 12px;
    margin-top: 12px;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    list-style-type: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__item {
    max-width: 216px;
    flex-shrink: 0;
  }

  &__question {
    outline: 0;
    border: 2px solid transparent;
    background: var(--tertiary-color);
    cursor: pointer;
    padding: 12px;
    background-color: var(--tertiary-color);
    border-radius: 12px;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    height: 100%;
    width: 100%;

    &:hover {
      border-color: var(--primary-color);
    }
  }
}
