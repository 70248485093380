.responseChatBubble {
  &__message {
    font-size: 16px;
    line-height: 25px;
    background-color: #FFFFFF;
    border-radius: 12px;
    border-top-left-radius: 0;
    padding: 12px 20px;
    box-shadow: 4px 4px 0px 0px #0000000D;
  }

  &__actionBlock {
    display: flex;

    &__button {
      padding: 0;
      outline: 0;
      border: 0;
      background: 0;
      cursor: pointer;
      color: var(--color-slate);
      position: relative;
      min-width: 44px;
      min-height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;

      &[aria-disabled="true"] {
        opacity: 0.5;
      }

      &:focus-visible {
        outline: -webkit-focus-ring-color auto 1px;
      }

      &:hover, &:focus {
        color: var(--primary-color);
      }

      &__icon {
        width: 14px;
      }

      &__tip {
        position: absolute;
        top: calc(100% - 8px);
        left: 50%;
        transform: translateX(-50%);
        background-color: var(--primary-color);
        color: #FFFFFF;
        padding: 4px 8px;
        border-radius: 8px;
        font-size: 12px;
        white-space: nowrap;

        &::after {
          content: "";
          position: absolute;
          top: -4px;
          left: 50%;
          transform: translateX(-50%);
          border: 4px solid transparent;
          border-bottom-color: var(--primary-color);
          border-top: 0;
        }
      }

      &--copied {
        color: var(--primary-color);
      }

      &--good {
        color: var(--primary-color);

        &[aria-disabled="true"] {
          opacity: 1;
        }
      }

      &--bad {
        color: #B61C14;

        &[aria-disabled="true"] {
          opacity: 1;
        }
      }
    }
  }
}

.feedbackModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #F7F7F7;
  width: 80%;
  max-width: 400px;
  height: 80%;
  max-height: 600px;
  z-index: 2;
  padding: 0 20px;
  border-radius: 4px;

  &__form {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__title {
      display: flex;
      gap: 8px;
      align-items: center;
      font-size: 18px;
      font-weight: bold;
      margin: 20px 0;
      justify-content: flex-start;
  
      &__icon {
        width: 14px;
        color: #B61C14;
      }
    }
  
    &__checkboxLabel {
      display: flex;
      align-items: center;
      gap: 12px;
      margin: 8px 0;
      cursor: pointer;
    }

    &__inputLabel {
      display: block;
      padding: 20px;
      background-color: #FFFFFF;
      border: 1px solid var(--color-grey);
      border-radius: 4px;
      margin-top: 20px;
      flex-grow: 1;
      max-height: 200px;
    }

    &__input {
      line-height: 26px;
      height: 100%;
    }
  
    &__submit {
      display: block;
      margin: 20px auto;
      padding: 8px 20px;
      border-radius: 99px;
      border: 2px solid var(--primary-color);
      cursor: pointer;
      font-weight: bold;
      color: var(--secondary-color);

      &[aria-disabled="true"] {
        cursor: not-allowed;
        opacity: 0.5;
      }

      &:hover, &:focus {
        background-color: var(--primary-color);
        color: #FFFFFF;
      }

      &__icon {
        width: 20px;
      }
    }

    &__close {
      margin-left: auto;
      margin-top: 24px;
      cursor: pointer;
      display: block;
      background: none;
      border: 0;
      outline: 0;

      &:focus-visible {
        outline: 2px solid var(--primary-color);
      }

      &__icon {
        width: 12px;
      }
    }
  }

  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000080;
    z-index: 1;
  }
}

fieldset {
  padding: 0;
  margin: 0;
  border: none;
}
