.checkbox {
  display: block;
  margin: 0;
  outline: 0;
  position: relative;
  width: 24px;
  height: 24px;
  appearance: none;

  &:focus-visible {
    &::after {
      border-color: var(--primary-color);
      outline: 1px solid var(--primary-color);
    }
  }

  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    background-color: #FFFFFF;
    border: 1px solid var(--color-grey);
    border-radius: 4px;
  }

  &:checked::after {
    background-repeat: no-repeat;
    background-position: 50%;
    background-image: url("data:image/svg+xml;utf8,<svg width='14' height='13' viewBox='0 0 14 13' xmlns='http://www.w3.org/2000/svg'> <path fill-rule='evenodd' clip-rule='evenodd' d='M13.786 1.61828L5.18366 12.5543L0.255112 7.05183L1.74489 5.71745L5.08428 9.44573L12.214 0.381775L13.786 1.61828Z' fill='%23002938'/></svg>");
  }
}