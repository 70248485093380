.userChatBubble {
  max-width: 75%;
  margin-left: auto;
  animation: fadeIn 500ms forwards;
  opacity: 0;

  &__message {
    font-size: 16px;
    line-height: 25px;
    color: #FFFFFF;
    background-color: var(--primary-color);
    border-radius: 12px;
    border-bottom-right-radius: 0;
    padding: 12px 20px;
    box-shadow: 4px 4px 0px 0px #0000000D;

    h3 {
      margin: 0;
      font-size: 16px;
      line-height: 25px;
      font-weight: normal;
    }
  }

  &__avatar {
    text-align: right;
    display: block;
    font-size: 14px;
    margin-top: 12px;
    color: var(--color-slate);
    position: relative;
  }
}
