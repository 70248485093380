.chatWindow {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 12px;
  max-width: 600px;
  margin: auto;

  &__messageView {
    margin-top: 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow-y: auto;
    padding: 0 20px;
    padding-bottom: 22px;
    position: relative;
    z-index: 1;
  }

  &__welcomeMessage {
    &--largeMargin {
      margin-bottom: 20px;
    }
  }

  &__assistantPrompt {
    margin-bottom: 20px;
  }

  &__bottomSection {
    position: relative;
    padding: 0 12px;
  }

  &__inputForm {
    display: flex;
    gap: 12px;
    padding: 0 5px 0 20px;
    background-color: #FFFFFF;
    border: 1px solid var(--color-grey);
    border-radius: 4px;
    align-items: center;

    &--disabled {
      background-color: #FAFAFA;
    }

    &__label {
      display: none;
    }

    &__input {
      max-height: 84px;
      margin-block: 15px;

      &[aria-disabled="true"] {
        background-color: #FAFAFA;
      }

      &:not([aria-disabled="true"]) {
        &:focus,
        &:active {
          color: var(--secondary-color);
        }

        &:focus + .chatWindow__inputForm__button > .chatWindow__inputForm__button__icon,
        &:active + .chatWindow__inputForm__button > .chatWindow__inputForm__button__icon {
          fill: var(--primary-color);
        }
      }
    }

    &__button {
      padding: 0;
      outline: 0;
      border: 0;
      background: 0;
      display: flex;
      min-width: 44px;
      min-height: 44px;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &__icon {
        width: 16px;
        fill: var(--color-grey);
      }

      &:focus-visible {
        outline: -webkit-focus-ring-color auto 1px;
      }
    }
  }
}

@keyframes fadeIn {
  0% { 
    opacity: 0; 
    transform: translateY(5px);
  }
  100% { 
    opacity: 1; 
    transform: translateY(0);
  }
}
