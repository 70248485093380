.markdown {
  &__link {
    color: var(--primary-color);
    text-decoration: underline;
    font-weight: bold;
    position: relative;
  }

  p:not(:last-child) {
    margin-bottom: 20px;
  }

  li:not(:last-child) {
    margin-bottom: 8px;
  }
}

footer .markdown {
  color: #65676b;
}

footer .markdown__link {
  color: #002938;
}
