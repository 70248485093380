.typingBubble {
  position: relative;
  font-size: 16px;
  background-color: #FFFFFF;
  border-radius: 12px;
  border-top-left-radius: 0;
  padding: 12px 20px;
  box-shadow: 4px 4px 0px 0px #0000000D;
  width: 24px;
  
  span {
    display: inline-block;
    animation: typingAnimation 1.2s infinite;
    animation-fill-mode: both;
    height: 5px;
    width: 5px;
    background: var(--tertiary-color);
    border-radius: 50%;
    
    &:nth-child(2) {
      animation-delay: .2s;
      margin-left: 3px;
    }
    
    &:nth-child(3) {
      animation-delay: .4s;
      margin-left: 3px;
    }
  }
}


@keyframes typingAnimation {
  0% {
    opacity: .1;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: .1;
  }
}