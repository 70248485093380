.chatModal {
  max-height: inherit;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  min-width: 310px;
  max-width: 540px;
  max-height: 460px;
  padding: 2rem;
  transform: translate(-50%, -50%);
  border-width: 0px;
  overflow: hidden;
  position: fixed;
  z-index: 2;
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  &__title {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0 0 0.75rem 0;
  }

  &__content {
    overflow: auto;
    font-size: 1rem;
    color: #65676b;
    margin: 0 0 2rem 0;
    line-height: 1.6;
  }

  &__cta {
    text-align: center;

    button {
      border-radius: 9999px;
      box-shadow: 0 0 0 2px var(--primary-color);
      color: var(--secondary-color);
      display: inline-flex;
      align-items: center;
      font-weight: 600;
      user-select: none;
      line-height: 1.5;
      transition:
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
      vertical-align: middle;
      cursor: pointer;
      white-space: nowrap;
      position: relative;
      appearance: none;
      border: 0;
      width: 100%;
      justify-content: center;
      padding: 0.5rem 1rem;
      font-size: 1.125rem;

      &:hover,
      &:focus {
        outline: 0;
        background-color: var(--tertiary-color);
        color: var(--secondary-color);
        box-shadow: inset 0 0 0 4px var(--secondary-color);
      }
    }
  }
}
